import styled, { css } from 'styled-components';
import { FlexBox, Text as TrustyText } from '@app/components/atoms';

export const Container = styled.div`
  background-color: #ebebeb;
  width: 100%;
`;

export const Content = styled.div<{ wide?: boolean; smallFooter?: boolean }>`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  max-width: ${({ wide }) => (wide ? 2000 : 1550)}px;
  font-family: Inter;
  padding: 90px 0 90px 8.12%;
  box-sizing: border-box;

  ${({ smallFooter }) =>
    smallFooter
      ? css`
          padding: 22px 12px;
        `
      : null};

  @media screen and (max-width: 1200px) {
    padding: 20px 30px;
  }
`;

export const ColumnsContainer = styled.div<{ smallFooter?: boolean }>`
  display: flex;
  gap: 19.36%;
  width: 100%;

  flex-direction: ${({ smallFooter }) => (smallFooter ? 'column' : 'row')};

  @media screen and (max-width: 1300px) {
    gap: 0;
    flex-direction: column;
  }
`;

export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 500px;
`;

export const LogoTextWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const ImageContainer = styled.div`
  height: 65px;
  width: 65px;
  position: relative;
  margin-right: 36px;
  cursor: pointer;

  @media screen and (max-width: 1200px) {
    min-height: 80px;
    min-width: 80px;
  }
`;

export const TextColumn = styled.div`
  flex-direction: column;
  width: 480px;
  display: flex;
  flex-grow: 1;
  max-width: 400px;
`;

export const SmallText = styled.div`
  color: #4f4f4f;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  width: 100%;
`;

export const NormalText = styled.div`
  color: #4f4f4f;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 20px;

  @media screen and (max-width: 1200px) {
    font-size: 16px;
  }
`;

export const Text = styled.div`
  color: #4f4f4f;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
`;

export const ButtonsWrapper = styled.div<{ smallFooter?: boolean }>`
  width: fit-content;
  font-family: 'Inter';
  display: flex;
  flex-direction: column;
  margin: 30px 0 80px;

  ${({ smallFooter }) =>
    smallFooter
      ? css`
          margin: 42px 0;
        `
      : null};

  @media screen and (max-width: 1200px) {
    margin: 42px 0;
  }
`;

export const ButtonContainer = styled.div``;

export const ContactText = styled(TrustyText)`
  font-size: 16px;
  font-weight: 500;

  &:hover {
    color: #2faf92;
  }
`;

export const RowCont = styled.div<{
  bigMargin?: boolean;
  hideMobile?: boolean;
}>`
  display: flex;
  align-items: center;
  margin-bottom: ${({ bigMargin }) => (bigMargin ? 26 : 13)}px;

  @media screen and (max-width: 1200px) {
    display: ${({ hideMobile }) => (hideMobile ? 'none' : 'flex')};
    margin-bottom: 23px;
  }
`;

export const ColumnCont = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 55px;
`;

export const DotSeparator = styled.div`
  color: ${(props) => props.theme.colors.secondary};
  font-family: 'Inter';
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  margin: 0 14px;
`;

export const Form = styled.form<{ smallFooter?: boolean }>`
  width: 375px;

  ${({ smallFooter }) =>
    smallFooter
      ? css`
          width: 100%;
        `
      : null};

  @media screen and (max-width: 1200px) {
    width: 100%;
  }
`;

export const RequiredText = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #4f4f4f;
  margin-top: 11px;
  margin-right: 15px;
  margin-bottom: 21px;

  span {
    color: ${(props) => props.theme.colors.secondary};
  }
`;

export const MobileWrapper = styled.div`
  display: block;
  margin-top: 45px;

  @media screen and (min-width: 975px) {
    display: none;
  }
`;

export const ThankYouTitle = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 27px;
  color: #4f4f4f;
  margin-top: 50px;
  margin-bottom: 17px;
`;

export const ThankYouMessage = styled.div<{ margin?: boolean }>`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  color: #4f4f4f;

  ${({ margin }) =>
    margin &&
    css`
      margin: 30px 0 60px;
    `};
`;

export const ThankYouEmail = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 27px;
  text-align: center;
  color: #4f4f4f;

  &:not(:last-child) {
    margin-bottom: 20px;
  }
`;

export const ErrorContainer = styled.div`
  min-height: 31px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-top: 9px;
`;

export const ButtonRow = styled.div`
  display: flex;
  justify-content: end;
  margin-top: 33px;
`;

export const ErrorText = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 22px;
  text-align: right;
  color: ${(props) => props.theme.colors.secondary};
`;

export const ThankContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const MyTrustySection = styled(FlexBox)`
  width: 100%;
  border-bottom: 1px solid #c4c4c4;
  padding-bottom: 6px;
  box-sizing: border-box;
  margin-bottom: 8px;
`;

export const MyTrustyText = styled(TrustyText)`
  font-size: 12px;
  font-weight: 700;
  color: #c4c4c4;
`;

export const SectionWrapper = styled(FlexBox)<{ noMargin?: boolean }>`
  width: 100%;
  margin-top: ${({ noMargin }) => (noMargin ? 0 : 18)}px;
  gap: 8px;
`;
