import React, { forwardRef, HTMLAttributes } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import * as Styled from './styles';

/**
 * Menu Button
 */
export interface MenuButtonProps extends HTMLAttributes<HTMLButtonElement> {}

const MenuButton = forwardRef<HTMLButtonElement, MenuButtonProps>(
  ({ ...rest }: MenuButtonProps, ref) => {
    return (
      <Styled.Container ref={ref} {...rest}>
        <FontAwesomeIcon icon={['fas', 'bars']} color="#4f4f4f" />
      </Styled.Container>
    );
  },
);

export default MenuButton;
