import styled, { css } from 'styled-components';
import FlexBox from '@app/components/atoms/FlexBox';
import Text from '@app/components/atoms/Text';
import Button from '@app/components/atoms/Button';

import IntroWeb from '/public/images/home/intro-web.png';
import IntroTablet from '/public/images/home/intro-tablet.png';
import IntroMobile from '/public/images/home/intro-mobile.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Container = styled.div`
  margin: 0;
  padding: 0;
  width: 100%;
  overflow-x: hidden;
`;

export const FullWidthContainer = styled.div`
  width: 100%;
  margin: 0 auto;
`;

export const HeroSection = styled.section`
  padding: 80px 0;
  text-align: center;
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url('/images/backgrounds/diagonal-homes-more-teal.png') repeat;
    background-size: 61.375px 61.375px;
    opacity: 0.5;
    z-index: 1;
    transform: rotate(15deg) scale(15);
    transform-origin: center;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      196deg,
      rgba(217, 217, 217, 0) 4.47%,
      #f9f9f9 58.24%
    );
    z-index: 1;
  }
`;

export const HeroContent = styled.div`
  max-width: 900px;
  margin: 0 auto;
  padding: 0 20px;
  position: relative;
  z-index: 3;
`;

export const ArrowRightIcon = styled(FontAwesomeIcon)`
  font-size: 18px;
  color: #fff;
  margin-left: 10px;
`;

export const HeroTitle = styled.h1`
  font-size: 75px;
  font-weight: 700;
  color: #4f4f4f;
  margin-bottom: 24px;
  line-height: 77px;

  @media (max-width: 500px) {
    font-size: 45px;
    line-height: 47px;
    margin-top: 0px;
  }
`;

export const HeroDescription = styled.p`
  font-size: 22px;
  line-height: 24px;
  color: #4f4f4f;
  margin-bottom: 32px;
  max-width: 650px;
  margin: 20px auto 80px;
`;

export const HeroButtonContainer = styled.div`
  display: flex;
  gap: 16px;
  justify-content: center;
  margin-bottom: 40px;

  @media (max-width: 850px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const TrustedSection = styled.section`
  padding: 0;
  text-align: center;
`;

export const TrustedTitle = styled.h2`
  font-family: 'Source Serif 4', serif;
  font-size: 32px;
  color: #4f4f4f;
  margin-bottom: 40px;
  font-weight: 400;
`;

export const BrokerageRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 60px;
  margin: 0 auto;
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
  position: relative;
  background-color: #fff;
`;

export const BrokerageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 60px;
  max-width: 1100px;
  margin: 0 auto;

  > div {
    padding: 40px 60px 40px 0;
    display: flex;
    align-items: center;

    &:not(:last-child) {
      position: relative;

      &:after {
        content: '';
        position: absolute;
        right: 0;
        top: -1px;
        bottom: -1px;
        width: 1px;
        background-color: #e5e5e5;
      }
    }

    &:last-child {
      padding-right: 0;
    }
  }

  @media (max-width: 768px) {
    flex-wrap: wrap;
    gap: 20px;
    padding: 0;

    > div {
      padding: 10px 20px 10px 0;

      &:not(:last-child):after {
        display: none;
      }
    }
  }

  img {
    height: 80px !important;
    width: auto;
    max-height: 80px;
    object-fit: contain;
    opacity: 0.8;
    transition: opacity 0.2s ease;

    &:hover {
      opacity: 1;
    }

    @media (max-width: 768px) {
      height: 60px !important;
      max-height: 60px;
    }
  }
`;

export const BrokerageLogos = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 60px;
  max-width: 1100px;
  margin: 0 auto;

  @media (max-width: 768px) {
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 20px;
  }

  img {
    width: auto;
    opacity: 0.8;
    transition: opacity 0.2s ease;
    height: 100px;

    &:hover {
      opacity: 1;
    }
  }
`;

export const OpportunitySection = styled.section`
  padding: 80px 0;
  width: 100%;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url('/images/backgrounds/diagonal_pattern_no_opacity.png')
      repeat;
    background-size: 400px;
    opacity: 0.1;
    z-index: 1;
  }
`;

export const OpportunityThreeT = styled.div`
  position: absolute;
  top: 0;
  left: 20px;
  right: 0;
  bottom: 0;
`;

export const OpportunityGrid = styled.div`
  display: grid;
  position: relative;
  grid-template-columns: repeat(2, 1fr);
  gap: 60px;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  position: relative;
  z-index: 2;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

export const TitleCard = styled.div`
  padding: 40px;
  z-index: 5;

  h2 {
    font-size: 45px;
    font-weight: 800;
    color: #4F4F4F;
    margin-bottom: 16px;
  }

  p {
    font-size: 18px;
    line-height: 20px;
    color: #4F4F4F;
  }

   @media (max-width: 500px) {
    h2 {font-size: 35px;}
    p {font-size: 16px;}
    padding-bottom: 0px;
    
`;

export const FeatureCard = styled.div`
  background: #ffffff;
  border-radius: 8px;
  padding: 32px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
`;

export const IconWrapper = styled.div`
  width: 75px;
  height: 75px;
  border-radius: 15px;
  background: #c5faef;
  box-shadow: 1px 3px 5px 0px rgba(0, 0, 0, 0.18);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
`;

export const Icon = styled(FontAwesomeIcon)`
  font-size: 32px;
  color: #0f897d;
`;

export const CardTitle = styled.h3`
  font-size: 24px;
  font-weight: 600;
  color: #4f4f4f;
  margin-bottom: 16px;
`;

export const CardDescription = styled.p`
  font-size: 16px;
  line-height: 1.5;
  color: #4f4f4f;
`;

export const PropertiesSection = styled.section`
  background: #f8f8f8;
  overflow: hidden;
  border-top: 1px solid #e5e5e5;
`;

export const PropertiesContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 60px;
  align-items: start;

  position: relative;

  @media (max-width: 1100px) {
    grid-template-columns: 1fr;
    gap: 40px;
  }
`;

export const PropertiesContent = styled.div`
  max-width: 480px;
  overflow-x: hidden;

  @media (max-width: 1100px) {
    max-width: 100%;
    text-align: center;
  }
`;

export const PropertiesTitle = styled.h2`
  font-size: 45px;
  font-weight: 800;
  color: #4f4f4f;
  margin-bottom: 48px;

  @media (max-width: 500px) {
    font-size: 35px;
    text-align: left;
  }
`;

export const FeatureList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  overflow: hidden;

  & > * {
    opacity: 0;
    transform: translateY(20px);
  }

  &.animate > *:nth-child(1) {
    animation: fadeInUp 0.6s ease forwards 0.1s;
  }
  &.animate > *:nth-child(2) {
    animation: fadeInUp 0.6s ease forwards 0.3s;
  }
  &.animate > *:nth-child(3) {
    animation: fadeInUp 0.6s ease forwards 0.5s;
  }

  @keyframes fadeInUp {
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

export const Feature = styled.div`
  display: flex;
  gap: 16px;
  align-items: flex-start;

  @media (max-width: 1100px) {
    text-align: left;
  }
`;

export const FeatureIcon = styled.div`
  width: 48px;
  height: 48px;
  background: #e5f3f3;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  svg {
    width: 24px;
    height: 24px;
    color: #2faf92;
  }
`;

export const FeatureContent = styled.div`
  flex: 1;
`;

export const FeatureTitle = styled.h3`
  font-size: 20px;
  font-weight: 600;
  color: #4f4f4f;
  margin-bottom: 8px;
  margin-top: 10px;
`;

export const FeatureDescription = styled.p`
  font-size: 16px;
  line-height: 1.5;
  color: #4f4f4f;
`;

export const PropertiesImage = styled.div`
  position: relative;
  width: 100%;
  height: 600px;
  margin-top: 48px;
  @media (max-width: 1100px) {
    margin-top: 0px;
    height: 500px;
  }
`;

export const ListBrowserWindow = styled.div`
  position: absolute;
  top: 100px;
  left: 0px;
  width: 380px;
  height: 100%;
  z-index: 1;

  @media (max-width: 1100px) {
    left: 0px;
    top: 0px;
  }
`;

export const DetailBrowserWindow = styled.div`
  position: absolute;
  top: 180px;
  left: 180px;
  width: 100%;
  max-width: 580px;
  height: calc(100% - 80px);
  z-index: 2;

  @media (max-width: 1100px) {
    left: 140px;
    top: 80px;
  }
`;

export const PropertiesThreeT = styled.div`
  position: absolute;
  top: 80px;
  right: 50px;
  transform: scale(0.8);

  @media (max-width: 1100px) {
    display: none;
  }
`;

export const LogoDivider = styled.div`
  height: 20px;
  background: url('/images/backgrounds/diagonal_pattern_no_opacity.png') repeat;
  background-size: 200px;
  opacity: 1;
  z-index: 1;
`;

export const BuyersSection = styled.section`
  padding: 40px 0 80px 0;
  background: #ffffff;
`;

export const BuyersContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 60px;
  align-items: center;

  @media (max-width: 968px) {
    grid-template-columns: 1fr;
    gap: 40px;
  }
`;

export const BuyersContent = styled.div`
  max-width: 480px;

  @media (max-width: 968px) {
    max-width: 100%;
  }
`;

export const BuyersTitle = styled.h2`
  font-size: 45px;
  font-weight: 800;
  color: #4f4f4f;
  margin-bottom: 48px;

  @media (max-width: 500px) {
    font-size: 35px;
    text-align: left;
  }
`;

export const BuyersList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  overflow: hidden;

  & > * {
    opacity: 0;
    transform: translateY(20px);
  }

  &.animate > *:nth-child(1) {
    animation: fadeInUp 0.6s ease forwards 0.1s;
  }
  &.animate > *:nth-child(2) {
    animation: fadeInUp 0.6s ease forwards 0.3s;
  }
  &.animate > *:nth-child(3) {
    animation: fadeInUp 0.6s ease forwards 0.5s;
  }

  @keyframes fadeInUp {
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

export const BuyersImage = styled.div`
  position: relative;
  width: 100%;
  height: 600px;
  margin-top: 0px;

  @media (max-width: 1100px) {
    margin-top: 0px;
    height: 500px;
  }
`;

export const BuyersImageBrowser = styled.div`
  position: absolute;
  top: 100px;
  left: 0px;
  width: 380px;
  height: 100%;
  z-index: 1;

  @media (max-width: 1100px) {
    left: 0px;
    top: 0px;
  }
`;

export const CTASection = styled.section`
  padding: 80px 0;
  background-color: #2f849a;
  position: relative;
  text-align: center;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url('/images/backgrounds/diagonal_pattern_no_opacity.png')
      repeat;
    background-size: 700px;
    opacity: 0.05;
    z-index: 1;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      176deg,
      rgba(47, 132, 154, 0) 3.33%,
      rgba(47, 132, 154, 0.5) 70.56%
    );
    z-index: 2;
  }
`;

export const CTAContainer = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  padding: 0 20px;
  position: relative;
  z-index: 3;
`;

export const CTATitle = styled.h2`
  font-size: 45px;
  font-weight: 800;
  color: #ffffff;
  margin-bottom: 24px;
  line-height: 1.2;

  @media (max-width: 768px) {
    font-size: 36px;
  }
`;

export const CTADescription = styled.p`
  font-size: 20px;
  max-width: 800px;
  margin: 0 auto 40px auto;
  line-height: 1.5;
  color: #ffffff;
  opacity: 0.9;

  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

export const CTAButton = styled.div`
  color: #2F849A;
  }
`;
