import styled, { css } from 'styled-components';
export type StyledHeaderButtonProps = {
  variant?: 'grey' | 'cyan';
  isMouseDown?: boolean;
  loading?: boolean;
};

const variantModifiers = {
  grey: css`
    background: #2faf92;
    color: white;
    border: none;

    &:hover {
      background: #278f78;
    }
  `,
  cyan: css`
    background: #fff;
    color: #4f4f4f;
    border: 1px solid #e6e6e6;

    &:hover {
      background: #f2f2f2;
    }
  `,
};

export const Button = styled.button<StyledHeaderButtonProps>`
  border: none;
  cursor: pointer;
  padding: 9px 24px;
  border-radius: 10px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  white-space: nowrap;

  &:focus {
    outline: unset;
  }

  ${({ variant = 'grey' }) => variantModifiers[variant]};

  ${({ isMouseDown = false }) =>
    isMouseDown &&
    css`
      transform: scale(0.97);
    `};
`;
