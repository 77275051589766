import styled, { css } from 'styled-components';
export type StyledButtonProps = {
  variant?:
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'quaternary'
    | 'cancel'
    | 'green'
    | 'clean';
  large?: boolean;
  width?: string;
  isMouseDown?: boolean;
  padding?: string;
  fontSize?: number;
  fontWeight?: string;
  loading?: boolean;
  disabledOpacity?: boolean;
};

const variantModifiers = {
  primary: css`
    background: #2faf92;
    color: white;
    border: none;

    &:hover {
      background: #278f78;
    }
  `,
  secondary: css`
    background: #fff;
    color: #4f4f4f;
    border: 1px solid #e6e6e6;

    &:hover {
      background: #f2f2f2;
    }
  `,
  tertiary: css`
    background: #f4f4f4;
    color: #4f4f4f;

    &:hover {
      background: #fefcf3;
    }
  `,
  quaternary: css`
    background: #50596f;
    color: #ffffff;

    &:hover {
      background: #485065;
    }
  `,
  green: css`
    background: #2faf92;
    color: white;
    border: none;

    &:hover {
      background: #278f78;
    }
  `,
  cancel: css`
    background: #d91515;
    color: #ffffff;
    &:hover {
      background: #d91515;
    }
  `,
  clean: css`
    background: transparent;
    box-shadow: none;
    color: #d47e5b;
    &:hover {
      background: transparent;
    }
  `,
};

const mouseDownModifiers = {
  primary: css`
    box-shadow: none;
    transform: translateY(2px);
    transition: 100ms;
  `,
  secondary: css`
    box-shadow: none;
    transform: translateY(2px);
    transition: 100ms;
  `,
  tertiary: css`
    box-shadow: none;
    transform: translateY(2px);
    transition: 100ms;
  `,
  quaternary: css`
    box-shadow: none;
    transform: translateY(2px);
    transition: 100ms;
  `,
  green: css`
    box-shadow: none;
    transform: translateY(2px);
    transition: 100ms;
  `,
  cancel: css`
    box-shadow: none;
    transform: translateY(2px);
    transition: 100ms;
  `,
  clean: css`
    box-shadow: none;
    transform: translateY(0px);
    transition: 100ms;
  `,
};

export const Button = styled.button<StyledButtonProps>`
  width: ${({ width }) => (width ? `${width}` : 'auto')};
  border: none;
  cursor: pointer;
  padding: ${({ large, padding }) =>
    large ? '18px 40px 16px' : padding || '15px 40px 15px'};
  border-radius: 15px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: ${({ fontWeight }) => fontWeight || '400'};
  font-size: ${({ large, fontSize }) =>
    large ? '21px' : fontSize ? `${fontSize}px` : '18px'};
  text-align: center;
  white-space: nowrap;
  transition: background 0.2s ease-out;

  &:focus {
    outline: unset;
  }

  ${({ variant = 'primary' }) => variantModifiers[variant]};

  ${({ isMouseDown = false, variant = 'primary' }) =>
    isMouseDown && mouseDownModifiers[variant]};

  ${({ disabled, variant, disabledOpacity }) =>
    disabled && variant !== 'clean'
      ? css`
          background: ${!disabledOpacity && '#b3b3b3'};
          color: ${!disabledOpacity && '#ddd'};
          cursor: unset;
          opacity: ${disabledOpacity ? 0.5 : 1};

          &:hover {
            background: ${!disabledOpacity && '#b3b3b3'};
          }
        `
      : disabled &&
        css`
          color: #ddd;
          cursor: unset;
        `}
`;
