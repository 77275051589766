import React from 'react';
import { Transition } from 'react-transition-group';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import LinkButton from '@app/components/atoms/LinkButton';

import * as Styled from './styles';
import Img from '@app/components/atoms/Img';
import useAuth from '@app/hooks/useAuth';
import { useQuery } from '@app/hooks';
import { CREATE_PORTAL_SESSION } from '@app/queries/createPortalSession';
import * as Sentry from '@sentry/nextjs';

interface MyWindow extends Window {
  Intercom?: (event: string, options?: unknown | null) => void;
}

/**
 * Mobile Menu
 */
export interface MobileMenuProps {
  visible: boolean;
  closeMenu: () => void;
  footerRef: any;
}

// TRANSITION STYLES
const bodyStyle = {
  transition: 'right 300ms ease-in-out',
  right: '-330px',
};
const bodyTransitionStyles: any = {
  entering: { right: '-330px' },
  entered: { right: 0 },
  exiting: { right: 0 },
  exited: { right: '-330px' },
};

const backdropStyle = {
  transition: 'opacity 300ms ease-in-out',
  opacity: 0,
};
const backdropTransitionStyles: any = {
  entering: { opacity: 1 },
  entered: { opacity: 1 },
};

const MobileMenu = ({ footerRef, visible, closeMenu }: MobileMenuProps) => {
  const { isLoggedIn, userData } = useAuth({});
  const userType = userData.userType || 'user';
  const subscriptionValid = !!(
    userData?.subscription?.status === 'trialing' ||
    userData?.subscription?.status === 'active'
  );

  const createPortalSession = useQuery({
    query: CREATE_PORTAL_SESSION,
    onResult: ({ createPortalSession }: any) => {
      window.location.href = createPortalSession;
    },
    onFail: (err) => {
      Sentry.captureException(err);
    },
  });

  const handleManageSubscription = () => {
    createPortalSession.execute();
  };

  const w = window as MyWindow;

  return (
    <>
      <Transition in={visible} timeout={0}>
        {(state) => (
          <Styled.MenuBody
            style={{ ...bodyStyle, ...bodyTransitionStyles[state] }}
          >
            <Styled.MenuWrapper>
              <Styled.Wrapper>
                <Styled.HeaderContainer>
                  <Styled.ImageContainer>
                    <Img
                      src="images/logoText.png"
                      layout="fill"
                      objectFit="contain"
                      alt="Trusty - Home Matching Network"
                    />
                  </Styled.ImageContainer>
                  <FontAwesomeIcon
                    icon={['fas', 'times']}
                    size="lg"
                    color="#4f4f4f"
                    onClick={closeMenu}
                  />
                </Styled.HeaderContainer>

                <Styled.ButtonsWrapper>
                  {isLoggedIn ? (
                    <>
                      {userType === 'user' && (
                        <Styled.ButtonContainer>
                          <LinkButton href="/my-trusty">My Trusty</LinkButton>
                        </Styled.ButtonContainer>
                      )}
                      {userType !== 'user' && (
                        <>
                          <Styled.MyTrustySection>
                            <Styled.MyTrustyText>My Trusty</Styled.MyTrustyText>
                          </Styled.MyTrustySection>
                          <Styled.SectionWrapper noMargin>
                            <Styled.ButtonContainer>
                              <LinkButton href="/my-trusty/properties">
                                Properties
                              </LinkButton>
                            </Styled.ButtonContainer>
                            <Styled.ButtonContainer>
                              <LinkButton href="/my-trusty/buyers">
                                Buyers
                              </LinkButton>
                            </Styled.ButtonContainer>
                          </Styled.SectionWrapper>
                        </>
                      )}

                      <Styled.SectionWrapper>
                        <Styled.ButtonContainer>
                          <LinkButton href="/account">Account</LinkButton>
                        </Styled.ButtonContainer>
                        {userType !== 'user' && (
                          <Styled.ButtonContainer>
                            <LinkButton href="/account/agent-details">
                              Agent details
                            </LinkButton>
                          </Styled.ButtonContainer>
                        )}
                        {userType !== 'user' && (
                          <Styled.ButtonContainer>
                            <LinkButton
                              href={subscriptionValid ? '#' : '/pricing'}
                              onClick={
                                subscriptionValid
                                  ? handleManageSubscription
                                  : undefined
                              }
                            >
                              Manage subscription
                            </LinkButton>
                          </Styled.ButtonContainer>
                        )}
                      </Styled.SectionWrapper>

                      <Styled.SectionWrapper>
                        <Styled.ButtonContainer
                          onClick={() => {
                            closeMenu();
                            w.Intercom && w.Intercom('show');
                          }}
                        >
                          <Styled.ContactText style={{ cursor: 'pointer' }}>
                            Contact Us
                          </Styled.ContactText>
                        </Styled.ButtonContainer>
                      </Styled.SectionWrapper>

                      <Styled.SectionWrapper>
                        <Styled.ButtonContainer>
                          <LinkButton href="/logout">Logout</LinkButton>
                        </Styled.ButtonContainer>
                      </Styled.SectionWrapper>
                    </>
                  ) : (
                    <>
                      <Styled.SectionWrapper>
                        <Styled.ButtonContainer>
                          <LinkButton href="/invite">
                            Invite your agent
                          </LinkButton>
                        </Styled.ButtonContainer>
                        <Styled.ButtonContainer>
                          <LinkButton href="/pricing">Pricing</LinkButton>
                        </Styled.ButtonContainer>
                        <Styled.ButtonContainer>
                          <LinkButton href="/pricing">Sign Up</LinkButton>
                        </Styled.ButtonContainer>
                        <Styled.ButtonContainer>
                          <LinkButton href="/login">Log In</LinkButton>
                        </Styled.ButtonContainer>
                      </Styled.SectionWrapper>
                      <Styled.SectionWrapper>
                        <Styled.ButtonContainer>
                          <LinkButton href="/about-us">About us</LinkButton>
                        </Styled.ButtonContainer>
                        <Styled.ButtonContainer
                          onClick={() => {
                            closeMenu();
                            w.Intercom && w.Intercom('show');
                          }}
                        >
                          <Styled.ContactText style={{ cursor: 'pointer' }}>
                            Contact us
                          </Styled.ContactText>
                        </Styled.ButtonContainer>
                      </Styled.SectionWrapper>
                    </>
                  )}
                </Styled.ButtonsWrapper>
              </Styled.Wrapper>

              <Styled.Wrapper>
                <Styled.RowCont>
                  <LinkButton href="/terms-of-use">Terms of Use</LinkButton>
                  <Styled.DotSeparator>•</Styled.DotSeparator>
                  <LinkButton href="/privacy-policy">Privacy Policy</LinkButton>
                </Styled.RowCont>

                <Styled.FooterTextWrapper>
                  <Styled.FooterText>
                    © Trusty Labs, Inc. All rights reserved, 2022
                  </Styled.FooterText>
                  <Styled.FooterText>
                    California DRE #01527508
                  </Styled.FooterText>
                </Styled.FooterTextWrapper>

                <Styled.RowCont>
                  <Styled.EqualImgCont>
                    <Img
                      src="images/equalHouseOportunity.png"
                      layout="fill"
                      objectFit="contain"
                      alt="Equal house oportunity"
                    />
                  </Styled.EqualImgCont>
                  <Styled.Text>Equal Housing Opportunity</Styled.Text>
                </Styled.RowCont>
              </Styled.Wrapper>
            </Styled.MenuWrapper>
          </Styled.MenuBody>
        )}
      </Transition>
      <Transition
        in={visible}
        mountOnEnter
        unmountOnExit
        onEnter={(node: any) => node.offsetHeight}
        timeout={300}
      >
        {(state) => (
          <Styled.MobileBackdrop
            onClick={closeMenu}
            style={{ ...backdropStyle, ...backdropTransitionStyles[state] }}
          />
        )}
      </Transition>
    </>
  );
};

export default MobileMenu;
