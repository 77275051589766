import React from 'react';
import Link from 'next/link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';

import * as Styled from './styles';
import { useRouter } from 'next/router';
/**
 * Header Select
 */
export interface HeaderSelectProps {
  footerRef: any;
}

const HeaderSelect = ({ footerRef }: HeaderSelectProps) => {
  const router = useRouter();

  return (
    <Styled.DropDown>
      <span>Learn</span>
      <Styled.IconSpan>
        <FontAwesomeIcon icon={['fas', 'caret-down']} size="sm" />
      </Styled.IconSpan>
      <Styled.Options>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Link href="/about-us" passHref>
            <Styled.Anchor
              active={Boolean(router?.asPath?.includes('/about-us'))}
            >
              About Us
            </Styled.Anchor>
          </Link>
          <Link href="/pricing" passHref>
            <Styled.Anchor
              active={Boolean(router?.asPath?.includes('/pricing'))}
            >
              Pricing
            </Styled.Anchor>
          </Link>
          <Styled.Anchor onClick={() => footerRef.current.scrollIntoView()}>
            Contact Us
          </Styled.Anchor>
        </div>
      </Styled.Options>
    </Styled.DropDown>
  );
};

export default HeaderSelect;
