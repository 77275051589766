import React from 'react';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';

import * as Styled from './styles';
import { useQuery } from '@app/hooks';
import { CREATE_PORTAL_SESSION } from '@app/queries/createPortalSession';
import * as Sentry from '@sentry/nextjs';

interface MyWindow extends Window {
  Intercom?: (event: string, options?: unknown | null) => void;
}

/**
 * Header Select
 */
interface HeaderSelectAuthProps {
  subscriptionValid?: boolean;
}

const HeaderSelectAuth = ({ subscriptionValid }: HeaderSelectAuthProps) => {
  const userData: any = useSelector<any>((state) => state.user);

  const createPortalSession = useQuery({
    query: CREATE_PORTAL_SESSION,
    onResult: ({ createPortalSession }: any) => {
      window.location.href = createPortalSession;
    },
    onFail: (err) => {
      Sentry.captureException(err);
    },
  });

  const handleManageSubscription = () => {
    createPortalSession.execute();
  };

  const w = window as MyWindow;

  return (
    <Styled.DropDown>
      <span>Welcome, {userData?.firstName}</span>
      <Styled.IconSpan>
        <FontAwesomeIcon icon={['fas', 'caret-down']} size="sm" />
      </Styled.IconSpan>
      <Styled.Options>
        <Styled.OptionsWrapper>
          {userData?.userType && userData?.userType === 'agent' ? (
            <>
              <Styled.MyTrustySection>
                <Styled.MyTrustyText>My Trusty</Styled.MyTrustyText>
              </Styled.MyTrustySection>
              <Styled.Anchor href="/my-trusty/properties">
                Properties
              </Styled.Anchor>
              <Styled.Anchor href="/my-trusty/buyers">Buyers</Styled.Anchor>
            </>
          ) : (
            <Styled.Anchor href="/my-trusty">My Trusty</Styled.Anchor>
          )}

          <Styled.SectionWrapper>
            <Styled.Anchor href="/account">Account</Styled.Anchor>
            {userData?.userType && userData?.userType === 'agent' && (
              <>
                <Styled.Anchor href="/account/agent-details">
                  Agent details
                </Styled.Anchor>
                <Styled.Anchor
                  href={subscriptionValid ? '#' : '/pricing'}
                  onClick={
                    subscriptionValid ? handleManageSubscription : undefined
                  }
                >
                  Manage subscription
                </Styled.Anchor>
              </>
            )}
          </Styled.SectionWrapper>

          <Styled.SectionWrapper>
            <Styled.Anchor onClick={() => w.Intercom && w.Intercom('show')}>
              Contact us
            </Styled.Anchor>
          </Styled.SectionWrapper>

          <Styled.SectionWrapper>
            <Styled.Anchor href="/logout">Logout</Styled.Anchor>
          </Styled.SectionWrapper>
        </Styled.OptionsWrapper>
      </Styled.Options>
    </Styled.DropDown>
  );
};

export default HeaderSelectAuth;
