// eslint-disable-next-line import/no-anonymous-default-export
export default {
  title: 'defaultTheme',
  colors: {
    primary: '#4f4f4f',
    secondary: '#2FAF92',
    background: '#f9f9f9',
    white: '#FFF',
    warning: '#FF7C33',
    error: '#CC4044',
    ice: {
      100: '#E5E5E5',
      150: '#F2F2F2',
      200: '#888888',
      250: '#969AA5',
      300: '#4F4F4F',
      400: '#767676',
    },
    blue: {
      100: '#6E7B9C',
      900: '#23262D',
    },
    greenBlue: {
      100: '#DEFFF9',
      200: 'rgba(47, 175, 146, 0.75)',
      300: '#2F849A',
    },
  },
  sizes: {
    desktop: '1550px',
    mobile: '975px',
  },
  typography: {
    title: {},
  },
};
