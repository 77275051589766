import styled from 'styled-components';

type StyledProps = {
  width?: string;
  height?: string;
};

export const BrowserWindow = styled.div<StyledProps>`
  width: ${(props) => props.width || '100%'};
  height: ${(props) => props.height || 'auto'};
  background: white;
  border-radius: 16px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  position: relative;
`;

export const BrowserHeader = styled.div`
  height: 44px;
  background: #f0f0f0;
  padding: 0 16px;
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const Circle = styled.div<{ color: string }>`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: ${(props) => props.color};
`;

export const BrowserContent = styled.div`
  width: 100%;
  height: calc(100% - 44px);
  position: relative;
  padding: 5px;
`;
