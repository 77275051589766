import styled from 'styled-components';

export const ButtonGroup = styled.div`
  display: flex;
  gap: 8px;
`;

export const Button = styled.button<{
  $isSelected: boolean;
  $variant: 'yes' | 'maybe' | 'no';
}>`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 0px 25px;
  border-radius: 15px;
  border: 1px solid;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  font-size: 16px;
  font-weight: 500;
  justify-content: center;
  border-radius: 15px;
  border: 1px solid #e6e6e6;
  height: 50px;
  background: #fff;

  ${({ $variant, $isSelected }) => {
    const variants = {
      yes: {
        default: {
          color: '#4F4F4F',
          borderColor: '#E0E0E0',
          background: '#FFFFFF',
          iconColor: '#0F897D',
        },
        hover: {
          background: '#F2F2F2',
        },
        selected: {
          color: '#FFFFFF',
          borderColor: '#0F897D',
          background: '#0F897D',
          iconColor: '#FFFFFF',
        },
      },
      maybe: {
        default: {
          color: '#4F4F4F',
          borderColor: '#E0E0E0',
          background: '#FFFFFF',
          iconColor: '#05C0AE',
        },
        hover: {
          background: '#F2F2F2',
        },
        selected: {
          color: '#FFFFFF',
          borderColor: '#05C0AE',
          background: '#05C0AE',
          iconColor: '#FFFFFF',
        },
      },
      no: {
        default: {
          color: '#4F4F4F',
          borderColor: '#E0E0E0',
          background: '#FFFFFF',
          iconColor: '#F76666',
        },
        hover: {
          background: '#F2F2F2',
        },
        selected: {
          color: '#FFFFFF',
          borderColor: '#F76666',
          background: '#F76666',
          iconColor: '#FFFFFF',
        },
      },
    };

    const style = $isSelected
      ? variants[$variant].selected
      : variants[$variant].default;
    return `
      color: ${style.color};
      border-color: ${style.borderColor};
      background: ${style.background};
      
      &:hover {
        background: ${
          $isSelected ? style.background : variants[$variant].hover.background
        };
      }

      .icon-color {
        color: ${style.iconColor};
      }
    `;
  }}

  &:active {
    transform: scale(0.98);
  }
`;

export const Icon = styled.span<{
  $variant: 'yes' | 'maybe' | 'no';
  $isSelected: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  width: 18px;
  height: 18px;

  svg {
    width: 100%;
    height: 100%;
  }
`;

export const Text = styled.span`
  font-weight: 500;
  font-size: 16px;
`;
