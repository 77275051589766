import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SellingStatusButtonProps } from './index';
import * as Styled from './styles';

const SellingStatusButton: React.FC<SellingStatusButtonProps> = ({
  value,
  onChange,
  yes_at,
  maybe_at,
  no_at,
}) => {
  React.useEffect(() => {
    if (value !== null) return;
    if (!yes_at && !maybe_at && !no_at) return;

    const dates = {
      yes: yes_at ? new Date(yes_at) : null,
      maybe: maybe_at ? new Date(maybe_at) : null,
      no: no_at ? new Date(no_at) : null,
    };

    const validDates = Object.entries(dates).filter(
      ([_, date]) => date !== null,
    );

    if (validDates.length === 0) return;

    const mostRecent = validDates.sort(
      ([_, a], [__, b]) => b!.getTime() - a!.getTime(),
    )[0];

    onChange(mostRecent[0] as 'yes' | 'maybe' | 'no');
  }, [yes_at, maybe_at, no_at, value, onChange]);

  const handleClick = (clickedValue: 'yes' | 'maybe' | 'no') => {
    if (value === clickedValue) {
      onChange(null);
    } else {
      onChange(clickedValue);
    }
  };

  return (
    <Styled.ButtonGroup>
      <Styled.Button
        $variant="yes"
        $isSelected={value === 'yes'}
        onClick={() => handleClick('yes')}
      >
        <Styled.Icon $variant="yes" $isSelected={value === 'yes'}>
          <FontAwesomeIcon icon={['fas', 'check']} className="icon-color" />
        </Styled.Icon>
        <Styled.Text>Yes</Styled.Text>
      </Styled.Button>
      <Styled.Button
        $variant="maybe"
        $isSelected={value === 'maybe'}
        onClick={() => handleClick('maybe')}
      >
        <Styled.Icon $variant="maybe" $isSelected={value === 'maybe'}>
          <FontAwesomeIcon icon={['fas', 'minus']} className="icon-color" />
        </Styled.Icon>
        <Styled.Text>Maybe</Styled.Text>
      </Styled.Button>
      <Styled.Button
        $variant="no"
        $isSelected={value === 'no'}
        onClick={() => handleClick('no')}
      >
        <Styled.Icon $variant="no" $isSelected={value === 'no'}>
          <FontAwesomeIcon icon={['fas', 'xmark']} className="icon-color" />
        </Styled.Icon>
        <Styled.Text>No</Styled.Text>
      </Styled.Button>
    </Styled.ButtonGroup>
  );
};

export default SellingStatusButton;
