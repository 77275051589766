import React from 'react';
import * as Styled from './styles';

interface BrowserWindowProps {
  children: React.ReactNode;
  width?: string;
  height?: string;
}

const BrowserWindow: React.FC<BrowserWindowProps> = ({
  children,
  width,
  height,
}) => {
  return (
    <Styled.BrowserWindow width={width} height={height}>
      <Styled.BrowserHeader>
        <Styled.Circle color="#FF5F57" />
        <Styled.Circle color="#FFBD2E" />
        <Styled.Circle color="#28C840" />
      </Styled.BrowserHeader>
      <Styled.BrowserContent>{children}</Styled.BrowserContent>
    </Styled.BrowserWindow>
  );
};

export default BrowserWindow;
