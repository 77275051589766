import { FlexBox, Text as TrustyText } from '@app/components/atoms';
import styled from 'styled-components';

export const MenuBody = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 9999;
  display: flex;
  justify-content: flex-start;
  width: 330px;
  background: #ffffff;
  padding: 24px 25px 27px 17px;
  box-sizing: border-box;
  font-family: 'Inter';
`;

export const MobileBackdrop = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(196, 196, 196, 0.5);
  z-index: 9998;
`;

export const MenuWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
`;

export const Wrapper = styled.div`
  display: flex;
  align-self: stretch;
  flex-direction: column;
  align-items: flex-start;
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 21px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ButtonContainer = styled.div``;

export const ContactText = styled(TrustyText)`
  font-size: 16px;
  font-weight: 400;

  &:hover {
    color: #2faf92;
  }
`;

export const RowCont = styled.div<{
  bigMargin?: boolean;
  hideMobile?: boolean;
}>`
  display: flex;
  align-items: center;
  margin-bottom: 23px;
`;

export const DotSeparator = styled.div`
  color: ${(props) => props.theme.colors.secondary};
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  margin: 0 14px;
`;

export const FooterTextWrapper = styled.div`
  margin-bottom: 55px;
`;

export const FooterText = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: #4f4f4f;
  margin-bottom: 4px;
`;

export const EqualImgCont = styled.div`
  height: 20px;
  width: 22px;
  position: relative;
  margin-right: 10px;
`;

export const Text = styled.div`
  color: #4f4f4f;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
`;

export const ImageContainer = styled.div`
  height: 40px;
  width: 103px;
  position: relative;
`;

export const MenuDivider = styled.div`
  height: 1px;
  width: 100%;
  background: black;
`;

export const SectionTitle = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: regular;
  font-size: 15px;
  color: #4f4f4f;
  margin-bottom: 9px;
`;

export const ContactContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
`;

export const FooterCont = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
`;

export const EqualHouseCont = styled.div`
  width: 39px;
  height: 39px;
  position: relative;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const MyTrustySection = styled(FlexBox)`
  width: 100%;
  border-bottom: 1px solid #c4c4c4;
  padding-bottom: 6px;
  box-sizing: border-box;
  margin-bottom: 8px;
`;

export const MyTrustyText = styled(TrustyText)`
  font-size: 12px;
  font-weight: 700;
  color: #c4c4c4;
`;

export const SectionWrapper = styled(FlexBox)<{ noMargin?: boolean }>`
  width: 100%;
  margin-top: ${({ noMargin }) => (noMargin ? 0 : 18)}px;
  gap: 8px;
`;
